import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, useDisclosure, Button, Box, Divider, Flex, Text } from "@chakra-ui/react";
import { Icon } from "@iconify/react";

interface IWarningModal {
  onAccept: () => void;
  onReject: () => void;
  message: string;
  children: any;
}

function WarningModal({ onAccept, message, children }: IWarningModal) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Box onClick={onOpen}>{children}</Box>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent mx={5}>
          <ModalHeader>Confirm</ModalHeader>
          {/* <ModalCloseButton /> */}
          <Divider />
          <ModalBody>
            <Flex justify={"center"} align={"start"} gap={2}>
              <Box bg={"blue.100"} borderRadius={"full"}>
                <Box m={2}>
                  <Icon icon={"flat-color-icons:info"} fontSize={24} />
                </Box>
              </Box>

              <Text fontSize={"sm"} fontWeight={"normal"}>
                {message}
              </Text>
            </Flex>
          </ModalBody>
          <Divider />
          <ModalFooter>
            <Button borderRadius={"full"} variant={"ghost"} mr={3} onClick={onClose} size={"sm"} fontWeight={"semibold"}>
              Review Again
            </Button>
            <Button variant="solid" size={"sm"} borderRadius={"full"} onClick={onAccept} px={4} fontWeight={"semibold"}>
              Confirm and Continue
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
export default WarningModal;
