import { Box, Flex, FormControl, FormLabel, Input, InputGroup, InputLeftAddon, InputProps, InputRightAddon } from "@chakra-ui/react";
import { Icon } from "@iconify/react/dist/iconify.js";
import { memo } from "react";
import { Controller, RegisterOptions } from "react-hook-form";

interface IInputBoxProps extends InputProps {
  label: string;
  prefix?: string;
  suffix?: string;
  icon?: string;
  control: any; // Adjust the type according to your form library
  registerOption?: RegisterOptions;
  min?: number; // Minimum value for the input
  max?: number; // Maximum value for the input
}

const InputBoxV2: React.FC<IInputBoxProps> = memo(({ color, registerOption, control, name, icon, maxLength, type, placeholder, prefix, isRequired, label, suffix, min, max, ...rest }) => {
  return (
    <Box>
      <FormControl isRequired={isRequired}>
        <FormLabel fontSize="sm" display="flex" color="gray.600">
          <Flex>
            {icon && (
              <Flex justify="center" align="center" borderRadius="full" h={5} w={5} mr={1} mt={-0.25} color={color || "primary.600"}>
                <Icon icon={icon} fontSize={20} />
              </Flex>
            )}
            {label}
          </Flex>
        </FormLabel>
        <InputGroup size="md">
          {prefix && <InputLeftAddon children={prefix} />}
          <Input
            type={type}
            placeholder={placeholder}
            maxLength={maxLength}
            fontWeight="normal"
            {...control.register(name, registerOption)}
            min={min} // Sets the minimum value
            max={max} // Sets the maximum value
            {...rest}
            fontSize="md"
          />
          {suffix && <InputRightAddon children={suffix} />}
        </InputGroup>
      </FormControl>
    </Box>
  );
});

export default InputBoxV2;
