import { Box, Flex, FormControl, FormLabel, InputProps } from "@chakra-ui/react";
import { Icon } from "@iconify/react/dist/iconify.js";
import { Controller, RegisterOptions } from "react-hook-form";
import Select from "react-select";

interface IInputBox extends InputProps {
  label: string;
  prefix?: string;
  icon?: string;
  control: any;
  registerOption?: RegisterOptions;
  options: any;
  isClearable?: boolean;
  isLoading?: boolean;
  onSelect?: any;
  isMulti?: boolean;
  paddingT?: number;
  paddingB?: number;
}

export default function DropdownHook({
  color,
  name,
  options,
  icon,
  label,
  isDisabled,
  placeholder,
  isLoading,
  isClearable,
  control,
  minWidth,
  registerOption,
  maxWidth,
  isMulti,
  defaultValue,
  isRequired,
  paddingT,
  paddingB,
}: IInputBox) {
  console.log("Child render");
  return (
    <Box>
      <FormControl isRequired={isRequired}>
        <FormLabel fontSize={"sm"} display={"flex"} color={"gray.600"}>
          <Flex>
            {icon && (
              <Flex justify={"center"} align={"center"} borderRadius={"full"} h={5} w={5} mr={1} mt={-0.25} color={color || "primary.600"}>
                <Icon icon={icon} fontSize={20} />
              </Flex>
            )}
            {label}
          </Flex>
        </FormLabel>

        <Controller
          control={control}
          name={name || ""}
          rules={registerOption}
          render={({ field: { onChange, onBlur, value, name, ref } }) => (
            <Select
              onChange={onChange}
              name={name}
              ref={ref}
              onBlur={onBlur}
              value={value}
              isMulti={isMulti}
              isDisabled={isDisabled}
              options={options}
              placeholder={placeholder}
              isLoading={isLoading}
              isClearable={isClearable}
              defaultValue={defaultValue}
              styles={{
                control: (baseStyles: any) => ({
                  ...baseStyles,
                  fontSize: 15,
                  fontWeight: 700,
                  minWidth: minWidth || 200,
                  maxWidth: maxWidth || 200,
                  minHeight: "40px",
                  height: "100%",
                  paddingTop: paddingT || 0,
                  paddingBottom: paddingB || 0,
                }),
                valueContainer: provided => ({
                  ...provided,
                  minHeight: "35px",
                  height: "100%",
                  padding: "0 6px",
                }),
                input: provided => ({
                  ...provided,
                  margin: "0px",
                  fontSize: 16,
                }),
                indicatorSeparator: () => ({
                  display: "none",
                }),
                indicatorsContainer: provided => ({
                  ...provided,
                  height: "35px",
                }),
                option: (base: any) => ({
                  ...base,
                  zIndex: 9999,
                  fontSize: 16,
                  fontWeight: 400,
                  letterSpacing: "0.02em",
                  textTransform: "capitalize",
                  backgroundColor: "white",
                  color: "black",
                }),
              }}
              // {...control.register(name, registerOption)}
            />
          )}
        />
      </FormControl>
    </Box>
  );
}
