import { FORM_ERROR_MESSAGE } from "@config/constant";
import { useCallback, useEffect } from "react";
import { FieldValues, useForm, useWatch } from "react-hook-form";

import { IHookFromOptions } from "@config/types";
import { debounce, throttle } from "lodash";

type TValueType = FieldValues | { [x: string]: any } | undefined;

interface IUseRedForm {
  initialValue: TValueType;
  onChange?: (status: boolean, props: TValueType) => void;
  onValidCb?: (props?: TValueType) => void;
}

export const InputFieldOptions: IHookFromOptions = {
  name: {
    required: FORM_ERROR_MESSAGE["Please type contact person name"],
  },
  number: {
    required: "Please type contact person name",
    minLength: {
      message: "Please type a valid mobile number",
      value: 10,
    },
    pattern: {
      message: "Please type valid mobile number.",
      value: /^[6789]\d{9}/,
    },
  },
};

export default function useRedForm({ initialValue, onChange, onValidCb }: IUseRedForm) {
  const { control, setValue, formState, watch, getValues } = useForm({
    defaultValues: initialValue,
  });

  const { isValid, isValidating } = formState;

  const onSubmit = throttle((value: any) => {
    // onChange && onChange(isValid, value);
    onValidCb && onValidCb(value);
    console.log(value);
  }, 300);

  // const formValues: any = useWatch({ control });

  // console.log(formValues);

  // useEffect(() => {
  //   if (isValid) {
  //     onSubmit(getValues());
  //   }
  // }, [isValidating]);

  useEffect(() => {
    if (isValid) {
      const value = getValues();
      onSubmit(value);
    }
    const subscription = watch(value => {
      if (isValid) {
        onSubmit(value);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, isValid]);

  return { control, setValue, getValues, isValid };
}
