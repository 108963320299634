import { Flex, Stack, Text, Switch } from "@chakra-ui/react";
import InputBoxV2 from "@components/atom/InputBoxV2";
import { InputFieldOptions } from "@config/constant";
import useRedForm from "@hooks/action/useRedForm";
import useOrderAction from "@store/action-hook/useOrderAction";
import { useRedStore } from "@store/store";
import { showErrorToast } from "@utils";
import { debounce } from "lodash";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { shallow } from "zustand/shallow";

const AdditionalDetailPatientSection = () => {
  const [requestedBy] = useRedStore(
    state => [state.localOrderChanges.requestedBy],
    () => false
  );

  const [requestedFor]: any = useRedStore(
    state => [state.localOrderChanges.requestedFor],
    () => false
  );

  const { __updateRequestedInfo } = useOrderAction();
  const [isRequesterPatient, setIsRequesterPatient] = useState(false);

  const initialValues = useMemo(
    () => ({
      requestedBy_name: requestedBy?.name,
      requestedBy_mobile: requestedBy?.mobile,
      requestedFor_name: requestedFor?.name,
      requestedFor_mobile: requestedFor?.mobile,
    }),
    [requestedFor, requestedBy]
  );

  const onChangeHandler = useCallback(
    (value: any) => {
      const reqFor = {
        name: value?.requestedFor_name,
        mobile: value?.requestedFor_mobile,
      };

      const reqBy = {
        name: value?.requestedBy_name,
        mobile: value?.requestedBy_mobile,
      };

      __updateRequestedInfo(reqFor, reqBy);
    },
    [__updateRequestedInfo]
  );

  const { control, setValue, getValues } = useRedForm({
    onValidCb: onChangeHandler,
    // onChange: onChangeHandler,
    initialValue: initialValues,
  });

  const isSame = requestedBy?.name == requestedFor?.name && requestedBy?.mobile == requestedFor?.mobile;

  const sameRequestedByAndRequestedFor = useCallback(
    (e: any) => {
      const isChecked = e.target.checked;
      setIsRequesterPatient(isChecked);
      const formValues = getValues();
      if (isChecked) {
        setValue("requestedFor_name", formValues?.requestedBy_name, {
          shouldValidate: true,
          shouldDirty: true,
        });
        setValue("requestedFor_mobile", formValues?.requestedBy_mobile, {
          shouldValidate: true,
          shouldDirty: true,
        });
      } else {
        setValue("requestedFor_name", initialValues.requestedFor_name, {
          shouldValidate: true,
          shouldDirty: true,
        });
        setValue("requestedFor_mobile", initialValues.requestedFor_mobile, {
          shouldValidate: true,
          shouldDirty: true,
        });
      }
    },
    [setValue, initialValues]
  );

  return (
    <Stack gap={7} w={"full"} mb={15}>
      <InputBoxV2
        label={"Contact Person name"}
        type="name"
        control={control}
        name="requestedBy_name"
        icon="mingcute:user-2-fill"
        minLength={3}
        isRequired
        placeholder="Person Name"
        registerOption={{
          required: true,
          min: 1,
        }}
      />
      <InputBoxV2
        label={"Contact Person Contact number"}
        prefix="+91"
        type="tel"
        placeholder="phone number"
        icon="ph:phone-fill"
        maxLength={10}
        isRequired
        control={control}
        name="requestedBy_mobile"
        registerOption={{
          required: true,
          min: 1,
        }}
      />
      <Flex justify={"space-between"} align={"center"}>
        <Flex direction="column">
          <Text fontWeight={"semibold"} fontSize={"sm"}>
            Contact is Patient
          </Text>
          <Text fontWeight={"normal"} fontSize={"xs"}>
            Please fill the Contact person details first to enable this option.
          </Text>
        </Flex>
        <Switch isChecked={isSame && isRequesterPatient} size={"md"} onChange={sameRequestedByAndRequestedFor} />
      </Flex>
      <InputBoxV2
        isDisabled={isSame && isRequesterPatient}
        label={"Patient name"}
        type="name"
        icon="solar:user-bold-duotone"
        minLength={3}
        isRequired
        placeholder="Patient name"
        control={control}
        name="requestedFor_name"
        registerOption={{
          required: true,
          min: 1,
        }}
      />
      <InputBoxV2
        isDisabled={isSame && isRequesterPatient}
        label={"Patient Contact number"}
        prefix="+91"
        type="tel"
        placeholder="phone number"
        icon="ph:phone-duotone"
        maxLength={10}
        isRequired
        control={control}
        name="requestedFor_mobile"
        registerOption={{
          required: true,
          min: 1,
        }}
      />
    </Stack>
  );
};

export default memo(AdditionalDetailPatientSection);
