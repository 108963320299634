import { Box, Button, Flex, Stack, Text } from "@chakra-ui/react";
import { GENDER_OPTIONS } from "../../config/constant";
import { useRedStore } from "@store/store";
import useOrderAction from "@store/action-hook/useOrderAction";
import DropdownHook from "@components/atom/DropdownHook";
import InputBoxV2 from "@components/atom/InputBoxV2";
import JointInputBox from "@components/atom/HightInputV2";
import useRedForm from "@hooks/action/useRedForm";
import { memo, useCallback, useMemo } from "react";

const AdditionalDetailPatientInfo = () => {
  const { __updateRequestedFor } = useOrderAction();
  const [requestedFor]: any = useRedStore(
    state => [state.localOrderChanges.requestedFor],
    () => false
  );

  const initialValues = useMemo(
    () => ({
      medicalIssue: {
        reason: requestedFor?.medicalIssue?.reason,
      },
      gender: requestedFor?.gender,
      weight: requestedFor?.weight,
      height: {
        feet: requestedFor?.height?.feet || 0,
        inches: requestedFor?.height?.inches || 0,
      },
      age: requestedFor?.age,
    }),
    [requestedFor]
  );

  const handleOnChange = useCallback(
    (value: any) => {
      __updateRequestedFor(value);
    },
    [__updateRequestedFor]
  );

  const { control, isValid } = useRedForm({
    onValidCb: handleOnChange,
    initialValue: initialValues,
  });

  return (
    <Stack gap={5} pt={5}>
      <DropdownHook
        label={"Medical Reason"}
        type="name"
        control={control}
        name="medicalIssue.reason"
        icon="solar:medical-kit-bold-duotone"
        minLength={3}
        isRequired={false}
        placeholder="Person Name"
        options={requestedFor?.medicalIssue?.reason}
        defaultValue={requestedFor?.medicalIssue?.reason}
        onSelect={() => console.log("")}
        minWidth={"full"}
        maxWidth={"full"}
        isMulti={true}
        isDisabled={true}
        isClearable={false}
        paddingT={3}
        paddingB={3}
      />
      <Flex gap={4}>
        <Box flex={1}>
          <InputBoxV2
            label={"Age"}
            type="tel"
            icon="uim:calender"
            isRequired
            placeholder="Age"
            control={control}
            name="age"
            suffix={"Years"}
            maxLength={3}
            registerOption={{
              valueAsNumber: true,
              required: true,
              min: 1,
              validate: value => value > 0,
            }}
          />
        </Box>
        <Box flex={1}>
          <DropdownHook
            label={"Gender"}
            type="name"
            control={control}
            name="gender"
            icon="ph:gender-intersex-duotone"
            isRequired
            placeholder="Gender"
            options={GENDER_OPTIONS}
            defaultValue={requestedFor?.gender}
            onSelect={() => console.log("first")}
            minWidth={"full"}
            maxWidth={"full"}
            isClearable={false}
            registerOption={{
              valueAsNumber: true,
              required: true,
            }}
          />
        </Box>
      </Flex>
      <InputBoxV2
        label={"Weight"}
        type="tel"
        icon="icon-park-twotone:weight"
        placeholder="Weight"
        control={control}
        name="weight"
        suffix={"kg"}
        max={1000}
        maxLength={4}
        registerOption={{
          valueAsNumber: true,
          // required: true,
          // min: 1,
          // validate: value => value > 0,
        }}
      />
      <JointInputBox
        label={"Height"}
        type="tel"
        icon="mingcute:line-height-fill"
        placeholder="Feet"
        secondaryPlaceholder="Inch"
        control={control}
        name="height.feet"
        secondaryName={"height.inches"}
        suffix={"Ft"}
        secondarySuffix="Inch"
        maxLength={2}
        registerOption={{
          valueAsNumber: true,
        }}
      />
    </Stack>
  );
};

export default memo(AdditionalDetailPatientInfo);
