import { Box, ChakraTheme, ColorProps, Flex, FormControl, FormLabel, Heading, Input, InputGroup, InputLeftAddon, InputProps, InputRightAddon, ResponsiveValue } from "@chakra-ui/react";
import { Icon } from "@iconify/react/dist/iconify.js";
import { RegisterOptions } from "react-hook-form";

interface IInputBox extends InputProps {
  label: string;
  prefix?: string;
  secondaryPrefix?: string;
  suffix?: string;
  secondarySuffix?: string;
  icon?: string;
  control: any;
  registerOption?: RegisterOptions;
  secondaryName: string;
  secondaryPlaceholder?: string;
}

// {...control.register(name, InputFieldOptions.number)}
export default function JointInputBox({
  secondaryPlaceholder,
  secondaryName,
  secondaryPrefix,
  secondarySuffix,
  color,
  registerOption,
  control,
  name,
  icon,
  maxLength,
  type,
  placeholder,
  prefix,
  isRequired,
  label,
  suffix,
  ...rest
}: IInputBox) {
  return (
    <Box>
      <FormControl>
        <FormControl isRequired={isRequired}>
          <FormLabel fontSize={"sm"} display={"flex"} color={"gray.600"}>
            <Flex>
              {icon && (
                <Flex justify={"center"} align={"center"} borderRadius={"full"} h={5} w={5} mr={1} mt={-0.25} color={color || "primary.600"}>
                  <Icon icon={icon} fontSize={20} />
                </Flex>
              )}
              {label}
            </Flex>
          </FormLabel>
          <Flex gap={2}>
            <InputGroup size={"md"}>
              {prefix && <InputLeftAddon children={prefix} />}
              <Input type={type} placeholder={placeholder} maxLength={maxLength} fontWeight={"normal"} {...control.register(name, registerOption)} {...rest} fontSize={"md"} />
              {suffix && <InputRightAddon children={suffix} />}
            </InputGroup>
            <InputGroup size={"md"}>
              {secondaryPrefix && <InputLeftAddon children={secondaryPrefix} />}
              <Input type={type} placeholder={secondaryPlaceholder} maxLength={maxLength} fontWeight={"normal"} {...control.register(secondaryName, registerOption)} {...rest} fontSize={"md"} />
              {secondarySuffix && <InputRightAddon children={secondarySuffix} />}
            </InputGroup>
          </Flex>
        </FormControl>
      </FormControl>
    </Box>
  );
}

{
  /* <Flex align={"center"} mt={1}>
  <Flex justify={"center"} align={"center"} borderRadius={"full"} h={8} w={8} mr={1} color={"primary.600"}>
    <Icon icon={"fluent:patient-24-filled"} fontSize={20} />
  </Flex>
  <Heading fontWeight={"normal"} fontSize={"sm"} textTransform={"capitalize"}>
    {label}
  </Heading>
</Flex>; */
}
