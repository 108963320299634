import { Box, Button, Divider, Flex, Heading, SimpleGrid, Stack, Text, Wrap, WrapItem, useColorModeValue } from "@chakra-ui/react";
import TextRenderWithHeading from "@components/atom/TextRenderWithHeading";
import TextRenderWithIcon from "@components/atom/TextRenderWithIcon";
import AdditionalDetailPatientInfo from "@components/molecules/AdditionalDetailPatientInfo";
import AdditionalDetailPatientSection from "@components/molecules/AdditionalDetailPatientSection";
import WarningModal from "@components/molecules/WarningModal";
import { CaseStatusRender } from "@components/table/CaseListingTableComponent";
import NetworkWrapper from "@components/wrapper/NetworkWrapper";
import { PATIENT_INFO_CONSTANT } from "@config/constant";
import { additionalDetailsSubmitButtonClicked, pageView } from "@config/event";
import { useTitle } from "@hooks/action/useUtils";
import { useGetCase } from "@hooks/api/useCaseApi";
import { useOrder } from "@hooks/api/useSave";

import { useRedStore } from "@store/store";
import { convertEpochToDate, flattenObject, isMobileNumber, showErrorToast } from "@utils";
import { getProperty } from "dot-prop";
import { useEffect } from "react";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { shallow } from "zustand/shallow";

export default function AdditionalDetails() {
  useTitle("Additional Details");
  const { id: orderId } = useParams();
  const navigate = useNavigate();
  const { ...rest }: any = useGetCase({ orderId });
  const [requestedBy, requestedFor] = useRedStore(state => [state.localOrderChanges.requestedBy, state.localOrderChanges.requestedFor]);
  const order = useRedStore(state => state.order, shallow);
  const extractValue = (key: string) => getProperty(order, key) || "";
  const darkSlate = useColorModeValue("white", "slate.800");

  const isEnabled = requestedBy.name && isMobileNumber(requestedBy.mobile) && requestedFor?.name && isMobileNumber(requestedFor?.mobile) && !!requestedFor?.age && requestedFor?.gender;
  // isWeightAllowed &&
  // isHeightAllowed;

  const { updateAdditionalDetails, ...rest2 } = useOrder();

  const handleSubmit = () => {
    const isWeightAllowed = !requestedFor?.weight
      ? true
      : requestedFor?.weight <= PATIENT_INFO_CONSTANT.WEIGHT.max && requestedFor.weight > PATIENT_INFO_CONSTANT.WEIGHT.min && !isNaN(requestedFor?.weight);

    const isHeightAllowed =
      !requestedFor?.height || (!requestedFor?.height.feet && !requestedFor?.height?.inches)
        ? true
        : requestedFor?.height?.feet &&
          requestedFor?.height?.feet <= PATIENT_INFO_CONSTANT.HEIGHT.max.ft &&
          !isNaN(requestedFor?.height?.feet) &&
          requestedFor?.height?.inches &&
          requestedFor?.height?.inches <= PATIENT_INFO_CONSTANT.HEIGHT.max.inch &&
          !isNaN(requestedFor?.height?.feet);

    const isAgeAllowed = !requestedFor?.age ? false : requestedFor?.age <= PATIENT_INFO_CONSTANT.AGE.max && requestedFor?.age >= PATIENT_INFO_CONSTANT.AGE.min && !isNaN(requestedFor?.age);

    if (!isAgeAllowed) {
      showErrorToast(`Maximum allowed age: ${PATIENT_INFO_CONSTANT.AGE.max} years.`, 4000);
      return;
    }

    if (!isWeightAllowed) {
      showErrorToast(`Maximum allowed weight: ${PATIENT_INFO_CONSTANT.WEIGHT.max} KG.`, 4000);
      return;
    }

    if (!isHeightAllowed) {
      showErrorToast(`Maximum allowed height: ${PATIENT_INFO_CONSTANT.HEIGHT.max.ft} feet ${PATIENT_INFO_CONSTANT.HEIGHT.max.inch} inch.`, 4000);
      return;
    }

    additionalDetailsSubmitButtonClicked({ orderId: orderId, payload: JSON.stringify({ requestedFor, requestedBy }) });

    updateAdditionalDetails({
      onSuccess: () => {
        navigate(`/case-history/${orderId}/case-overview`);
      },
    });
  };

  useEffect(() => {
    pageView("Additional Details", {
      orderId: orderId,
    });
  }, []);

  return (
    <NetworkWrapper status={rest}>
      <Stack>
        <Wrap w={"full"} justify={"space-between"} bg={darkSlate} align={"center"} px={6} py={4} borderRadius={"md"}>
          <SimpleGrid columns={2} w="full" gap={4}>
            <TextRenderWithHeading title="Order Id" description={extractValue("orderId")} />
            <WrapItem maxW={["full", 200]}>
              <Box>
                <Text fontSize={"xs"} fontWeight={"normal"} color="blackAlpha.600">
                  Case status
                </Text>
                <CaseStatusRender status={extractValue("orderStatus")} />
              </Box>
            </WrapItem>
            <WrapItem maxW={["full", 200]}>
              <Box>
                <Text fontSize={"xs"} fontWeight={"normal"} color="blackAlpha.600">
                  Created At
                </Text>
                <Text fontSize={"xs"} fontWeight={"semibold"} color="blackAlpha.900">
                  {convertEpochToDate(parseInt(extractValue("createdAt")), "DD MMM YYYY hh:mm A")}
                </Text>
              </Box>
            </WrapItem>
            <WrapItem maxW={["full", 200]}>
              <TextRenderWithHeading title="Source" description={extractValue("originSource.source")} />
            </WrapItem>
          </SimpleGrid>
        </Wrap>
        <Wrap w={"full"} bg={darkSlate} px={6} py={4} mt={6} spacing={10} borderRadius={"md"}>
          <Box w={"full"} mb={"-6"}>
            <Heading fontWeight={"semibold"} fontSize={"small"} color={"gray.700"}>
              Case Details
            </Heading>
            <Divider my={2} />
          </Box>

          <WrapItem maxW={"full"}>
            <TextRenderWithIcon icon="material-symbols:location-on-outline" title="Pickup Location" render={() => <LocationCard location={extractValue("waypoints[0].address.location")} />} />
          </WrapItem>
          <WrapItem maxW={"full"}>
            <TextRenderWithIcon icon="mingcute:navigation-line" title="Drop Location" render={() => <LocationCard location={extractValue("waypoints[1].address.location")} />} />
          </WrapItem>
          <SimpleGrid columns={1} w={"full"} gap={10}>
            <WrapItem maxW={400}>
              <TextRenderWithIcon icon={"ph:taxi-bold"} title={"Ambulance Type"} description={extractValue("entityRequired[0].subtype")} />
            </WrapItem>
            <WrapItem w={"90vw"}>
              {extractValue("scheduledDdtm") && (
                <TextRenderWithIcon icon={"mdi:clock-time-three-outline"} title={"Scheduled on"} description={convertEpochToDate(parseInt(extractValue("scheduledDdtm")), "DD MMM YYYY hh:mm A")} />
              )}
            </WrapItem>
          </SimpleGrid>
        </Wrap>
        <Wrap w={"full"} bg={darkSlate} px={6} py={4} mt={6} spacing={10} borderRadius={"md"}>
          <Box w={"full"} mb={"-6"}>
            <Heading fontWeight={"semibold"} fontSize={"small"} color={"gray.700"}>
              Patient Contact Details
            </Heading>
            <Divider my={2} />
          </Box>

          <AdditionalDetailPatientSection />
        </Wrap>

        <Wrap w={"full"} bg={darkSlate} px={6} py={4} mt={6} spacing={10} borderRadius={"md"}>
          <Box w={"full"} mb={"-6"}>
            <Heading fontWeight={"semibold"} fontSize={"small"} color={"gray.700"}>
              Patient Details
            </Heading>
            <Divider my={2} />
          </Box>
          <Box mt={-6}>
            <AdditionalDetailPatientInfo />
          </Box>
        </Wrap>
        <WarningModal message="After you confirm, you can't change this data. Please review carefully before updating." onAccept={handleSubmit} onReject={() => console.log("first")}>
          <Button
            my={5}
            px={8}
            color={"white"}
            rounded={"md"}
            // onClick={handleSendPaymentLink}
            w={"full"}
            isLoading={rest2.isLoading}
            isDisabled={!isEnabled}
            fontWeight={"semibold"}
            colorScheme="primary"
          >
            Update Details
          </Button>
        </WarningModal>
      </Stack>
    </NetworkWrapper>
  );
}

const LocationCard = ({ location }: { location: string }) => {
  // eslint-disable-next-line no-unsafe-optional-chaining
  const [name] = location?.split(",");
  return (
    <Flex direction="column">
      <Box>
        <Text fontSize="medium" fontWeight="semibold" color={"black"}>
          {name || ""}
        </Text>
      </Box>
      <Text fontSize="xs" color="gray.700" fontWeight="normal">
        {location}
      </Text>
    </Flex>
  );
};
